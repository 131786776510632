import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSearch } from '_hooks'
export const SearchContext = React.createContext()

export const SearchProvider = ({ children }) => {
  const { push } = useHistory()
  const [searchValue, setSearchValue] = React.useState('')
  const { result } = useSearch(searchValue)

  React.useEffect(() => {
    if (result && result.length) {
      push('/busqueda')
    }
  }, [result])

  return (
    <SearchContext.Provider value={{
      result,
      search: (value) => setSearchValue(value),
      value: searchValue
    }}>
      {children}
    </SearchContext.Provider>
  )
}

