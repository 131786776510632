import React from 'react'
import { Wrapper } from '_components'

const Privacity = () => {
  return (
    <>
      <div className='text-center'>
        <h2>ICONOLABS</h2>
        <span>MANUAL DE POLÍTICAS DE TRATAMIENTO DE DATOS PERSONALES</span>
        <span>Abril de 2019.</span>
      </div>
      <br />
      <Wrapper>
        <strong>1. Principios generales y postulados.</strong>
        <p>Iconolabs, garantiza la protección de derechos como el Habeas Data, la privacidad, la intimidad, el buen nombre,  con tal propósito todas las actuaciones se regirán por principios de buena fe, legalidad, autodeterminación informática, libertad y transparencia.</p>
        <p>Quien en ejercicio de cualquier actividad, incluyendo  comerciales y laborales, sean estas permanentes u ocasionales pueda suministrar cualquier tipo de información o dato personal a Iconolabs y en la cual esta actúe como encargada del tratamiento o responsable del tratamiento podrá conocerla, actualizarla y rectificarla.</p>
        <br />
        <strong>2. Marco legal.</strong>
        <p>Constitución Política, artículo 15.</p>
        <p>Ley 1266 de 2008; Ley 1581 de 2012</p>
        <p>Decretos Reglamentarios 1727 de 2009 y 2952 de 2010, y Decreto Reglamentario parcial No 1377 de 2013 Sentencias de la Corte Constitucional C -1011 de 2008, y C -748 del 2011;</p>
        <br />
        <strong>3. Definiciones.</strong>
        <p>De conformidad con la legislación vigente sobre la materia, se establecen las siguientes definiciones, las cuales serán aplicadas e implementadas acogiendo los criterios de interpretación que garanticen una aplicación sistemática e integral, y en consonancia con los avances tecnológicos, la neutralidad tecnológica; y los demás principios y postulados que rigen los derechos fundamentales que circundan, orbitan y rodean el derecho de habeas data y protección de datos personales.</p>
        <p>Autorización: Consentimiento previo, expreso e informado del titular para llevar a cabo el Tratamiento de datos personales.</p>
        <p>Base de datos: Conjunto organizado de datos personales que sea objeto de Tratamiento.</p>
        <p>Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.</p>
        <p>Encargado del tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el tratamiento de datos personales por cuenta del responsable del tratamiento.</p>
        <p>Responsable del tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el tratamiento de los datos.</p>
        <p>Titular: Persona natural cuyos datos personales sean objeto de tratamiento.</p>
        <p>Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.</p>
        <br />
        <strong>4. Principios específicos</strong>
        <p>Iconolabs aplicará los siguientes principios específicos que se establecen a continuación, los cuales constituyen las reglas a seguir en la recolección, manejo, uso, tratamiento, almacenamiento e intercambio, de datos personales:</p>
        <p>a) Principio de legalidad: En el uso, captura, recolección y tratamiento de datos personales, se dará aplicación a las disposiciones vigentes y aplicables que rigen el tratamiento de datos personales y demás derechos fundamentales conexos.</p>
        <p>b) Principio de libertad: El, uso, captura, recolección y tratamiento de datos personales sólo puede llevarse a cabo con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal, estatutario, o judicial que releve el consentimiento.</p>
        <p>c) Principio de finalidad: El uso, captura, recolección y tratamiento de datos personales a los que tenga acceso y sean acopiados y recogidos por Iconolabs, estarán subordinados y atenderán una finalidad legítima, la cual debe serle informada al respectivo titular de los datos personales.</p>
        <p>d) Principio de veracidad o calidad: La información sujeta a uso, captura, recolección y tratamiento de datos personales debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.</p>
        <p>e) Principio de transparencia: En el uso, captura, recolección y tratamiento de datos personales debe garantizarse el derecho del Titular a obtener de Iconolabs de los Andes, en cualquier momento y sin restricciones, información acerca de la existencia de cualquier tipo de información o dato personal que sea de su interés o titularidad.</p>
        <p>f) Principio de acceso y circulación restringida: Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o terceros autorizados. Para estos propósitos la obligación de Iconolabs, será de medio.</p>
        <p>g) Principio de seguridad: Los datos personales e información usada, capturada, recolectada y sujeta a tratamiento por Iconolabs, será objeto de protección en la medida en que los recursos técnicos y estándares mínimos así lo permitan, a través de la adopción de medidas tecnológicas de protección, protocolos, y todo tipo de medidas administrativas que sean necesarias para otorgar seguridad a los registros y repositorios electrónicos evitando su adulteración, modificación, pérdida, consulta, y en general en contra de cualquier uso o acceso no autorizado.</p>
        <p>h) Principio de confidencialidad: Todas y cada una de las personas que administran, manejen, actualicen o tengan acceso a informaciones de cualquier tipo que se encuentre en Bases o Bancos de Datos, se comprometen a conservar y mantener de manera estrictamente confidencial y no revelarla a terceros, todas las informaciones personales, comerciales, contables, técnicas, comerciales o de cualquier otro tipo suministradas en la ejecución y ejercicio de sus funciones. Todas las personas que trabajen actualmente o sean vinculadas a futuro para tal efecto, en la administración y manejo de bases de datos, deberán suscribir un documento adicional u otrosí a su contrato laboral o de prestación de servicios para efectos de asegurar tal compromiso. Esta obligación persiste y se mantiene inclusive después de finalizada su relación con alguna de las labores que comprende el Tratamiento.</p>
        <br />
        <strong>5. Dato sensible</strong>
        <p>Se entiende por datos sensibles aquellos que afectan la intimidad del titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos, entre otros, la captura de imagen fija o en movimiento, huellas digitales, fotografías, iris, reconocimiento de voz, facial o de palma de mano, etc.</p>
        <p>5.1 Tratamiento de datos sensibles:</p>
        <p>Se podrá hacer uso y tratamiento de los datos catalogados como sensibles cuando:</p>
        <p>a) El Titular haya dado su autorización explícita a dicho tratamiento, salvo en los casos que por ley no sea requerido el otorgamiento de dicha autorización.</p>
        <p>b) El Tratamiento sea necesario para salvaguardar el interés vital del titular y este se encuentre física o jurídicamente incapacitado. En estos eventos, los representantes legales deberán otorgar su autorización.</p>
        <p>c) El Tratamiento sea efectuado en el curso de las actividades legítimas y con las debidas garantías por parte de una fundación, ONG, asociación o cualquier otro organismo sin ánimo de lucro, cuya finalidad sea política, filosófica, religiosa o sindical, siempre que se refieran exclusivamente a sus miembros o a las personas que mantengan contactos regulares por razón de su finalidad. En estos eventos, los datos no se podrán suministrar a terceros sin la autorización del titular.</p>
        <p>d) El Tratamiento se refiera a datos que sean necesarios para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.</p>
        <p>Sin perjuicio de las excepciones previstas en la ley, en el tratamiento se requiere la autorización previa, expresa e informada del titular, la cual deberá ser obtenida por cualquier medio que pueda ser objeto de consulta y verificación posterior.</p>
        <p>5.3 Casos en que no se requiere la autorización:</p>
        <p>La autorización del Titular no será necesaria cuando se trate de:</p>
        <p>a) Información requerida por una entidad pública o administrativa en ejercicio de sus Funcione s legales o por orden judicial.</p>
        <p>b) Datos de naturaleza pública.</p>
        <p>c) Casos de urgencia médica o sanitaria.</p>
        <p>d) Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos.</p>
        <p>e) Datos relacionados con el Registro Civil de las Personas.</p>
        <br />
        <strong>6. Derechos de los niños, niñas y adolescentes.</strong>
        <p>En el Tratamiento se asegurará el respeto a los derechos prevalentes de los menores.</p>
        <p>Queda proscrito el Tratamiento de datos personales de menores, salvo aquellos datos que sean de naturaleza pública.</p>
        <p>Es tarea del Estado y las entidades educativas de todo tipo proveer información y capacitar a los representantes legales y tutores sobre los eventuales riesgos a los que se enfrentan los menores respecto del tratamiento indebido de sus datos personales, y proveer de conocimiento acerca del uso responsable y seguro por parte de niños, niñas y adolescentes de sus datos personales, su derecho a la privacidad y protección de su información personal y la de los demás.</p>
        <br />
        <strong>7. Deberes de Iconolabs como responsable del Tratamiento de Datos Personales.</strong>
        <p>Iconolabs, cuando actúe como Responsables del Tratamiento de datos personales, cumplirá con los siguientes deberes:</p>
        <p>a) Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.</p>
        <p>b) Solicitar y conservar, copia de la respectiva autorización otorgada por el titular.</p>
        <p>c) Informar debidamente al titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada.</p>
        <p>d) Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
        <p>e) Garantizar que la información que se suministre al encargado del tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.</p>
        <p>f) Actualizar la información, comunicando de forma oportuna al encargado del tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a este se mantenga actualizada.</p>
        <p>g) Rectificar la información cuando sea incorrecta y comunicar lo pertinente al encargado del tratamiento.</p>
        <p>h) Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo Tratamiento esté previamente autorizado.</p>
        <p>i) Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular.</p>
        <p>j) Tramitar las consultas y reclamos formulados.</p>
        <p>k) Informar al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.</p>
        <p>l) Informar a solicitud del Titular sobre el uso dado a sus datos.</p>
        <p>m) Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.</p>
        <br />
        <strong>8. Registro Nacional de bases de datos.</strong>
        <p>Iconolabs, se reserva, en los eventos contemplados en la ley y en sus estatutos y reglamentos internos, la facultad de mantener y catalogar determinada información que repose en sus bases o bancos de datos, como confidencial de acuerdo con las normas vigentes, sus estatutos y reglamentos, todo lo anterior y en consonancia con el derecho fundamental y constitucional a la educación, a la libre cátedra, y principalmente de la autonomía Universitaria.</p>
        <p>Iconolabs, procederá de acuerdo con la normatividad vigente y la reglamentación que para tal fin expida el Gobierno Nacional, a realizar el registro de sus bases de datos, ante El Registro Nacional de Bases de Datos (RNBD) que será administrado por la Superintendencia de Industria y Comercio. El RNBD., es el directorio público de las bases de datos sujetas a Tratamiento que operan en el país; y que será de libre consulta para los ciudadanos, de acuerdo con la normatividad que para tal efecto expida el Gobierno Nacional.</p>
        <br />
        <strong>9. Autorizaciones y consentimiento.</strong>
        <p>La recolección, almacenamiento, uso, circulación o supresión de datos personales por parte de Iconolabs, requiere del consentimiento libre, previo, expreso e informado del titular de los mismos.</p>
        <p>9.1 Medio y manifestaciones para otorgar la autorización.</p>
        <p>La autorización puede constar en un documento físico, electrónico, mensaje de datos, Internet, Sitios Web, en cualquier otro formato que permita garantizar su posterior consulta, o mediante un mecanismo técnico o tecnológico idóneo, que permita manifestar u obtener el consentimiento vía clic o doble clic, mediante el cual se pueda concluir de manera inequívoca, que de no haberse surtido una conducta del titular, los datos nunca hubieren sido capturados y almacenados en la base de datos. La autorización será generada por Iconolabs y será puesto a disposición del titular con antelación y de manera previa al tratamiento de sus datos personales.</p>
        <br />
        <strong>10. Aviso de privacidad</strong>
        <p>El Aviso de Privacidad es el documento físico, electrónico o en cualquier otro formato conocido o por conocer, que es puesto a disposición del Titular para el tratamiento de sus datos personales. A través de este documento se informa al Titular la información relativa a la existencia de las políticas de tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las características del tratamiento que se pretende dar a los datos personales.</p>
        <p>Ver Anexo No 2 modelo de aviso de privacidad.</p>
        <p>10.1 Alcance y contenido del Aviso de Privacidad.</p>
        <p>El Aviso de Privacidad, como mínimo, deberá contener la siguiente información:</p>
        <p>a) La identidad, domicilio y datos de contacto del Responsable del Tratamiento.</p>
        <p>b) El tipo de tratamiento al cual serán sometidos los datos y la finalidad del mismo.</p>
        <p>c) Los mecanismos generales dispuestos por el Responsable para que el Titular conozca la política de tratamiento de la información y los cambios sustanciales que se produzcan en ella. En todos los casos, debe informar al titular cómo acceder o consultar la política de tratamiento de información.</p>
        <br />
        <strong>11. Prerrogativas y demás derechos de los titulares de la información.</strong>
        <p>En atención y en consonancia con lo dispuesto en la normatividad vigente y aplicable en materia de protección de datos personales, el titular de los datos personales tiene los siguientes derechos:</p>
        <p>a) Acceder, conocer, rectificar y actualizar sus datos personales frente a La Universidad de los Andes, en su condición de responsable del tratamiento.</p>
        <p>b) Por cualquier medio valido, solicitar prueba de la autorización otorgada a Iconolabs, en su condición de Responsable del Tratamiento.</p>
        <p>c) A recibir información por parte de Iconolabs, previa solicitud, respecto del uso que le ha dado a sus datos personales.</p>
        <p>d) Acudir ante las autoridades legalmente constituidas, en especial ante la Superintendencia de Industria y Comercio, y presentar quejas por, infracciones a lo dispuesto en la normatividad vigente en las normas aplicables, previo trámite de consulta o requerimiento ante el Responsable del Tratamiento.</p>
        <p>e) Modificar y revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales vigentes.</p>
        <p>f) Tener conocimiento y acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.</p>
        <br />
        <strong>12. Deberes de Iconolabs en relación con el tratamiento de los datos personales.</strong>
        <p>Iconolabs, tendrá presente, en todo momento, que los datos personales son propiedad de las personas a las que se refieren y que sólo ellas pueden decidir sobre los mismos. En este sentido, hará uso de ellos sólo para aquellas finalidades para las que se encuentra facultado debidamente, y respetando en todo caso la normatividad vigente sobre protección de datos personales.</p>
        <br />
        <strong>13. Garantías del Derecho de Acceso.</strong>
        <p>Iconolabs garantizará el derecho de acceso cuando, previa acreditación de la identidad del titular, legitimidad, o personalidad de su representante, poniendo a disposición de’ éste, sin costo o erogación alguna, de manera pormenorizada y detallada, los respectivos datos personales a través de todo tipo de medio, incluyendo los medios electrónicos que permitan el acceso directo del Titular a ellos. Dicho acceso deberá ofrecerse sin límite alguno y le deben permitir al titular la posibilidad de conocerlos y actualizarlos en línea.</p>
        <br />
        <strong>14. Consultas.</strong>
        <p>Los titulares, o sus causahabientes podrán consultar la información personal del Titular que repose en cualquier base de datos. En consecuencia, Iconolabs garantizará el derecho de consulta, suministrando a los titulares, toda la información contenida en el registro individual o que esté vinculada con la identificación del Titular.</p>
        <p>Con respecto a la atención de solicitudes de consulta de datos personales Iconolabs garantiza:</p>
        <p>Habilitar medios de comunicación electrónica u otros que considere pertinentes.</p>
        <p>Establecer formularios, sistemas y otros métodos simplificados, mismos que deben ser informados en el aviso de privacidad.</p>
        <p>Utilizar los servicios de atención al cliente o de reclamaciones que tiene en operación.</p>
        <p>En cualquier caso, independientemente del mecanismo implementado para la atención de solicitudes de consulta, las mismas serán atendidas en un término máximo de diez (10) días hábiles contados a partir de la fecha de su recibo. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado antes del vencimiento de los 10 días, expresando los motivos de la demora y señalando la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer plazo.</p>
        <br />
        <strong>15. Reclamos.</strong>
        <p>El Titular o sus causahabientes que consideren que la información contenida en una base de datos debe ser objeto de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la Ley, podrán presentar un reclamo ante el Responsable del Tratamiento, canalizándola y remitiéndola a través de la dependencia designada y cuyos datos de contacto se concretan más adelante en el numeral 22 del presente documento y que ejercerá la función de protección de datos personales al interior de Iconolabs.</p>
        <p>El reclamo lo podrá presentar el Titular, teniendo en cuenta la información señalada en el artículo 15 de la Ley 1581 de 2012 y en el decreto1377 de 2013, y demás normas que los modifiquen o adicionen.</p>
        <br />
        <strong>16. Implementación de procedimientos para garantizar el derecho a presentar reclamos.</strong>
        <p>En cualquier momento y de manera gratuita el titular o su representante podrán solicitar a personal de Iconolabs, la rectificación, actualización o supresión de sus datos personales, previa acreditación de su identidad.</p>
        <p>Los derechos de rectificación, actualización o supresión únicamente se podrán ejercer por:</p>
        <p>a) El titular o sus causahabientes, previa acreditación de su identidad, o a través de instrumentos electrónicos que le permitan identificarse.</p>
        <p>b) Su representante, previa acreditación de la representación.</p>
        <p>Cuando la solicitud sea formulada por persona distinta del titular, deberá acreditarse en debida forma la personería o mandato para actuar; y en caso de no acreditar tal calidad, la solicitud se tendrá por no presentada.</p>
        <p>La solicitud de rectificación, actualización o supresión debe ser presentada a través de los medios habilitados por Iconolabs señalados en el aviso de privacidad y contener, como mínimo, la siguiente información:</p>
        <p>El nombre y domicilio del titular o cualquier otro medio para recibir la respuesta.</p>
        <p>Los documentos que acrediten la identidad o la personalidad de su representante.</p>
        <p>La descripción clara y precisa de los datos personales respecto de los cuales el titular busca ejercer alguno de los derechos.</p>
        <p>En caso dado otros elementos o documentos que faciliten la localización de los datos personales.</p>
        <br />
        <strong>17. Rectificación y actualización de datos.</strong>
        <p>La Iconolabs tiene la obligación de rectificar y actualizar a solicitud del titular, la información de éste que resulte ser incompleta o inexacta, de conformidad con el procedimiento y los términos arriba señaladas. Al respecto se tendrá en cuenta lo siguiente:</p>
        <p>En las solicitudes de rectificación y actualización de datos personales el titular debe indicar las correcciones a realizar y aportar la documentación que avale su petición.</p>
        <p>Iconolabs, tiene plena libertad de habilitar mecanismos que le faciliten el ejercicio de este derecho, siempre y cuando éstos beneficien al titular. En consecuencia, se podrán habilitar  medios electrónicos u otros que considere pertinentes.</p>
        <p>Iconolabs, podrá establecer formularios, sistemas y otros métodos simplificados, mismos que deben ser informados en el aviso de privacidad y que se pondrán a disposición de los interesados en la página web.</p>
        <br />
        <strong>18. Supresión de datos.</strong>
        <p>El titular tiene el derecho, en todo momento, a solicitar a Iconolabs, la supresión (eliminación) de sus datos personales cuando:</p>
        <p>a) Considere que los mismos no están siendo tratados conforme a los principios, deberes y obligaciones previstas en la normatividad vigente.</p>
        <p>b) Hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron recabados.</p>
        <p>c) Se haya superado el periodo necesario para el cumplimiento de los fines para los que fueron recabados.</p>
        <p>Esta supresión implica la eliminación total o parcial de la información personal de acuerdo con lo solicitado por el titular en los registros, archivos, bases de datos o tratamientos realizados por Iconolabs. Es importante tener en cuenta que el derecho de cancelación no es absoluto y el responsable puede negar el ejercicio del mismo cuando:</p>
        <p>a) El titular tenga un deber legal o contractual de permanecer en la base de datos.</p>
        <p>b) La eliminación de datos obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas.</p>
        <p>c) Los datos sean necesarios para proteger los intereses jurídicamente tutelados del titular; para realizar una acción en función del interés público, o para cumplir con una obligación legalmente adquirida por el titular.</p>
        <br />
        <strong>19. Revocatoria de la autorización.</strong>
        <p>Los titulares de los datos personales pueden revocar el consentimiento al tratamiento de sus datos personales en cualquier momento, siempre y cuando no lo impida una disposición legal o contractual. Para ello, Iconolabs deberá establecer mecanismos sencillos y gratuitos que permitan al titular revocar su consentimiento, al menos por el mismo medio por el que lo otorgó.</p>
        <p>Se deberá tener en cuenta que existen dos modalidades en las que la revocación del consentimiento puede darse. La primera, puede ser sobre la totalidad de las finalidades consentidas, esto es, que Iconolabs deba dejar de tratar por completo los datos del titular; la segunda, puede ocurrir sobre tipos de tratamiento determinados, como por ejemplo para fines publicitarios o de estudios de mercado. Con la segunda modalidad, esto es, la revocación parcial del consentimiento, se mantienen a salvo otros fines del tratamientos que el responsable, de conformidad con la autorización otorgada puede llevar a cabo y con los que el titular está de acuerdo.</p>
        <br />
        <strong>20. Seguridad de la información y medidas de seguridad.</strong>
        <p>En desarrollo del principio de seguridad establecido en la normatividad vigente, Iconolabs adoptara las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
        <br />
        <strong>21. Utilización y transferencia internacional de datos personales e información personal por parte de Iconolabs.</strong>
        <p>Dependiendo de la naturaleza de las relaciones permanentes u ocasionales que cualquier persona titular de datos personales pueda tener para con Iconolabs, la totalidad de su información puede ser transferida al exterior, sujeto a los requerimientos legales aplicables, con la aceptación de la presente política, autoriza expresamente para transferir Información Personal. La información será transferida, para todas las relaciones que puedan establecerse con Iconolabs.</p>
        <p>Sin perjuicio de la obligación de observar y mantener la confidencialidad de la información, Iconolabs, tomará las medidas necesarias para que esos terceros conozcan y se comprometan a observar esta Política, bajo el entendido de que la información personal que reciban, únicamente podrá ser utilizada para asuntos directamente relacionados con la relación propia de la relación con Iconolabs, y solamente mientras ésta dure, y no podrá ser usada o destinada para propósito o fin diferente.</p>
        <p>Iconolabs, también puede intercambiar Información Personal con autoridades gubernamentales o públicas de otro tipo (incluidas, entre otras autoridades judiciales o administrativas, autoridades fiscales y organismos de investigación penal, civil, administrativa, disciplinaria y fiscal), y terceros participantes en procedimientos legales civiles y sus contadores, auditores, abogados y otros asesores y representantes, porque es necesario o apropiado: (a) para cumplir con las leyes vigentes, incluidas las leyes distintas a las de su país de residencia; (b) para cumplir con procesos jurídicos; (c) para responder las solicitudes de las autoridades públicas y del gobierno, y para responder las solicitudes de las autoridades públicas y del gobierno distintas a las de su país de residencia; (d) para hacer cumplir nuestros términos y condiciones; (e) para proteger nuestras operaciones; (f) para proteger nuestros derechos, privacidad, seguridad o propiedad, los suyos o los de terceros; y (g) obtener las indemnizaciones aplicables o limitar los daños y perjuicios que nos puedan afectar.</p>
        <br />
        <strong>22. Función de protección de datos personales al interior de Iconolabs.</strong>
        <p>Iconolabs como institución, y en los términos establecidos en la normatividad vigente, actuara como RESPONSABLE DEL TRATAMIENTO de Datos Personales; y las distintas dependencias tanto académicas como administrativas actuaran como ENCARGADOS DEL TRATAMIENTO de datos personales, así por ejemplo, y entre otras dependencias, para el caso de datos personales de estudiantes actúa como encargado del tratamiento la Oficina de Admisiones y Registros; para el caso de datos financieros y comerciales actúa como encargado del tratamiento la Vicerrectoria Administrativa y Financiera; para el caso de empleados de la Universidad actúa como encargado del tratamiento la Dirección de Gestión Humana y Organizacional, para el caso de egresados actúa como encargado del tratamiento la oficina de egresados de la Vicerrectoría de desarrollo y egresados.</p>
        <p>Iconolabs designa a la oficina del OMBUDSPERSON, o la dependencia que haga sus veces, como la persona que recibirá, procesara y canalizara las distintas solicitud que se reciban, y las hará llegar a la respectiva dependencia ya mencionadas encargadas del tratamiento, dependencias que una vez reciban estas comunicaciones, entraran a cumplir con la función de protección de datos personales, y deberán dar trámite a las solicitudes de los titulares, en los términos, plazos y condiciones establecido por la normatividad vigente, para el ejercicio de los derechos de acceso, consulta, rectificación, actualización, supresión y revocatoria a que se refiere la normatividad vigente sobre protección de datos personales.</p>
        <p>En el evento en que usted considere que se dio un uso contrario al autorizado y a las leyes aplicables, podrá contactarnos a través de una comunicación motivada dirigida a info@iconolabs.com. Dirección oficinas: Carrera 54 No. 53-20 Oficina del Ombudsperson, Correo electrónico: info@iconolabs.com; Teléfono: 2205235</p>
        <br />
        <p className='text-center'>
          <strong>VIGENCIA.</strong>
        </p>
        <p>El presente manual rige a partir del primero (01) de abril de 2019 y deja sin efectos los reglamentos o manuales especiales que se hubiesen podido adoptar por instancias académicas y/o administrativas en Iconolabs.</p>
        <br />
        <p className='text-center'>
          <strong>ANEXO 1</strong>
          <br />
          <strong className='text-center'>DOCUMENTO DE AUTORIZACIÓN Y REFRENDACIÓN DE USO DE DATOS PERSONALES</strong>
        </p>
        <p>Con la expedición de la ley 1581 de 2012 y el Decreto 1377 de 2013, se desarrolla el principio constitucional que tienen todas las personas a conocer, actualizar y rectificar todo tipo de información recogida o, que haya sido objeto de tratamiento de datos personales en bancos o bases de datos y, en general en archivos de entidades públicas y/o privadas.</p>
        <p>Para proceder de esta manera, y dar cumplimiento a la Ley 1581 de 2012 y el Decreto 1377 de 2013, le manifestamos que sus datos personales que han sido obtenidos  en cursos o encuestas se encuentran incluidos en nuestras bases de datos.</p>
        <p>Los derechos que le asisten como titular de la información son: conocer, actualizar, rectificar y solicitar la supresión de sus datos personales; solicitar la prueba de la autorización otorgada; ser informado sobre el uso que se da a sus datos personales; presentar quejas por infracciones a lo dispuesto en las normas de datos personales; revocar la autorización otorgada y consultar de forma gratuita los datos personales previamente autorizados.</p>
        <p>Si usted desea que sus datos sean suprimidos de nuestras bases de datos le solicitamos manifestarlo en forma expresa en el término de treinta (30) días hábiles, contados a partid del envío de esta comunicación. De lo contrario, se considera que nos autoriza para que los mismos sean utilizados para los siguientes propósitos: Envío de publicaciones relacionadas con temas de interés de la comunidad científica; prestar asistencia y soporte técnico de nuestros productos y servicios; proveer nuestro productos y servicios; promover nuestro productos y servicios; dar cumplimiento a las normas a las obligaciones contraídas, los cambios en productos y servicios y permitir la evaluación de la calidad  los mismos.</p>
        <p>Para ejercer sus derechos usted puede comunicarse a la línea 571 2205235 o al correo electrónico info@iconolabs.com o en nuestra página web en donde encontrará las políticas de tratamiento de los datos: www.iconolabs.com</p>
      </Wrapper>
    </>
  )
}

export default Privacity
