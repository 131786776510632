import React from 'react'
import styled from 'styled-components'
import Card from './Card'

const List = ({ products }) => {
  return (
    <List.Container>
      {products.map((value, index) => (
        <Card key={index} product={value} />
      ))}
    </List.Container>
  )
}

List.Container = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 249px));
  grid-gap: 1.5rem;
  justify-content: center;
`

export default List
