import React from 'react'
import styled from 'styled-components'
import { Button } from '_components'
import Input from '../Input'

const Signup = () => {
  const [step, setStep] = React.useState(0)
  function handleSubmit(form) {
    // petición al backend para hacer login
  }

  function handleNext() {
    if (step < 5) {
      setStep(step + 1)
    }
  }

  function handlePrev() {
    if (step > 0) {
      setStep(step - 1)
    }
  }

  return (
    <Signup.Container step={step}>
      <div>
      <h3>¡Registrate!</h3>
        <p>Inicia sesión y empieza a disfrutar de los mejores <strong>descuentos</strong></p>
      </div>
      <form id="Signup" onSubmit={handleSubmit}>
        <div>
          <Input
            id="firstName"
            name="firstName"
            label="Nombres"
            type="text"
            required
          />
          <Input
            id="lastName"
            name="lastName"
            label="Apellidos"
            type="text"
            required
          />
        </div>
        <div>
          <Input
            id="phone"
            name="phone"
            label="Celular"
            type="phone"
            required
          />
          <Input
            id="address"
            name="address"
            label="Dirección"
            type="text"
            required
          />
        </div>
        <div>
          <Input
            as="select"
            id="gender"
            name="gender"
            label="Genero"
            required
          >
            <option>asd</option>
            <option>aasd</option>
          </Input>
          <Input
            id="birthday"
            name="birthday"
            label="Fecha de nacimiento"
            type="date"
            required
          />
        </div>
        <div>
          <Input
            as="select"
            className="countries"
            id="countryId"
            name="country"
            label="Pais"
            required
          >
            <option value="">Seleccione un pais</option>
          </Input>
          <Input
            as="select"
            className="states"
            id="stateId"
            name="addressState"
            label="Departamento"
            required
          >
            <option value="">Seleccione un departamento</option>
          </Input>
          <Input
            as="select"
            id="cityId"
            className="cities"
            name="city"
            label="Ciudad"
            required
          >
            <option value="">Seleccione una ciudad</option>
          </Input>
        </div>
        <div>
          <Input
            id="email"
            name="emial"
            label="Correo"
            type="email"
            required
          />
          <Input
            id="password"
            name="password"
            label="Contraseña"
            type="password"
            required
          />
          <Input
            id="confirm-password"
            name="confirm-password"
            label="Confirmar contraseña"
            type="password"
            required
          />
        </div>
        <div>
          <Input
            id="code"
            name="code"
            label="Código de referido"
            type="text"
            required
          />
          <Button>Registrarme</Button>
        </div>
      </form>
      <Signup.StepButtonsContainer>
        {step ? <Button onClick={handlePrev}>Anterior</Button> : null}
        {(step < 5) ? <Button onClick={handleNext}>Sigiente</Button> : null}
      </Signup.StepButtonsContainer>
    </Signup.Container>
  )
}

Signup.Container = styled.div`
  overflow: hidden;
  a {
    color: #868E96;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
  }

  form {
    width: 600%;
    transition: all .3s;
    margin-left: ${({ step }) => `-${step}00%`};
    display: flex;
    > div {
      width: 100%;
      padding: 0 3px;
    }
  }
`

Signup.StepButtonsContainer = styled.div`
  margin-top: 1rem;
  text-align: center;
`

  export default Signup
