import React from 'react'
import axios from 'axios'
import API from '_api'

function useGetActiveProducts() {
  const signal = axios.CancelToken.source()
  const [products, setProducts] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  async function getActiveProducts (page = 1) {
    setLoading(true)
    const response = await API.Product.activeProducts(page, { cancelToken: signal.token })
    const list = response.reduce((acc, category) => {
        return [...acc, ...category.properties]
    }, [])

    setProducts(list)
    setLoading(false)
  }

  React.useEffect(() => {
    return function cleanup() {
      signal.cancel()
    }
  }, [])


  return { products, loading, getActiveProducts }
}

export default useGetActiveProducts
