import React from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'
import { Wrapper } from '_components'
import {
  SideBar,
  Profile,
  Address,
  Referrals,
  Loyalty,
  Discounts,
  Shopping
} from './components'

const Account = () => {
  return (
    <Wrapper>
      <Account.Container>
        <SideBar />
        <Switch>
          <Route exact path="/cuenta" component={Profile} />
          <Route path="/cuenta/direcciones" component={Address} />
          <Route path="/cuenta/fidelizacion" component={Loyalty} />
          <Route path="/cuenta/descuentos" component={Discounts} />
          <Route path="/cuenta/compras" component={Shopping} />
          <Route path="/cuenta/afiliados" component={Referrals} />
        </Switch>
      </Account.Container>
    </Wrapper>
  )
}

Account.Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-gap: 3rem;
  margin-top: 2rem;
`

export default Account
