import React from 'react'
import { session } from '_utils'
import API from '_api/index'

export const ShoppingCartContext = React.createContext()

export const ShoppingCartProvider = (props) => {
  const [branchs, setBranchs] = React.useState({})
  const [shoppingCartProducts, setShoppingCartProducts] = React.useState(
    session.getCartProducts() || {}
  )

  function saveProducts(products) {
    setShoppingCartProducts(products)
    session.setCartProducts(products)
  }

  function setProductToCart(id) {
    let newProducts = {...shoppingCartProducts}

    if (shoppingCartProducts[id]) {
      newProducts[id] = shoppingCartProducts[id] + 1
    } else {
      newProducts[id] = 1
    }

    saveProducts(newProducts)
  }

  function removeProductToCart(id) {
    let newProducts = {...shoppingCartProducts}
    if (shoppingCartProducts[id] > 1) {
      newProducts[id] = shoppingCartProducts[id] - 1
    } else {
      delete newProducts[id]
    }
    saveProducts(newProducts)
  }

  React.useEffect(() => {
    async function getProduct(id) {
      const result = await API.Product.detailProduct(id)
      return result
    }

    async function getProducts() {
      const products = await Promise.all(
        Object.keys(shoppingCartProducts)
          .map((id) => getProduct(id))
      )

      const branchProducts = products.reduce((acc, product) => {
        const branch = acc[product.nameBranch] || []
        return {
          ...acc,
          [product.nameBranch]: [...branch, product]
        }
      }, {})

      setBranchs(branchProducts)
    }

    getProducts()
  }, [shoppingCartProducts])

  return (
    <ShoppingCartContext.Provider
      value={{
        cartAmount: Object.keys(shoppingCartProducts).length,
        setProductToCart,
        removeProductToCart,
        shoppingCartProducts,
        branchs
      }}
    >
      {props.children}
    </ShoppingCartContext.Provider>
  )
}
