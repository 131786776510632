import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { currencyFormatter, slugify } from '_utils'
import Stars from '../Stars'

const Card = ({ product }) => {
  return (
    <Card.Container to={`/producto/${slugify(product.name)}/${product._id}`} data-testid="container">
      <Card.ImageContainer>
        <Card.Image role="presentation" src={product.image[0]} alt={product.name} />
      </Card.ImageContainer>
      <Card.TextContainer>
        <Card.Title>{product.name}</Card.Title>
        <Stars amount={product.qualification} />
        <Card.PriceContainer>
          {product.price_discount ? <Card.Price>{currencyFormatter.format(product.price_discount)}</Card.Price> :  null}
          {product.price && <Card.Price type={product.price_discount ? 'discount' : 'default'}>{currencyFormatter.format(product.price)}</Card.Price>}
        </Card.PriceContainer>
      </Card.TextContainer>
    </Card.Container>
  )
}

Card.Container = styled(Link)`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 6px;
  transition: all .3s;

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  }
`

Card.Title = styled.h3`
  color: #022E37;
  font-size: 16px;
  font-family: 'Lato';
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 0;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`

Card.TextContainer = styled.div`
  padding: 0 0.5rem 0.5rem 0.5rem;
`

Card.ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 6px;
`

Card.Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

Card.PriceContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

Card.Price = styled.div`
  font-weight: bold;
  font-size: ${({ type }) => type === 'discount' ? '14px' : '16px'};
  color: ${({ type }) => type === 'discount' ? '#868E96' : '#008BA7'};
  text-decoration: ${({ type }) => type === 'discount' ? 'line-through' : 'unset'};
`

export default Card
