import React from 'react'
import API from '_api'

function useSearch(searchValue) {
  const [result, setResult] = React.useState([])
  React.useEffect(() => {
    async function searchProduct (searchValue) {
      if (searchValue) {
        const result = await API.Product.searchProduct(searchValue, {
          page: 1,
          location: {
            lat: 0,
            ln: 0
          }
        })

        setResult(result[0].products)
      }
    }

    searchProduct(searchValue)
  }, [searchValue])

  return {result}
}

export default useSearch
