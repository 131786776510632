import React from 'react'
import { Wrapper, Stores, Categories } from '_components'
import API from '_api'

const SubCategories = (props) => {
  const id = props.match.params.id
  const slug = props.match.params.slug
  const [categories, setCategories] = React.useState([])

  async function getCategories (section, page) {
    const records = await API.Category.filterBySection(section, page)
    if (records) {
      setCategories(records)
    }
  }

  React.useEffect(() => {
    getCategories(props.match.params.id, 1)
  }, [props.match.params.id])

  return (
    <Wrapper>
      <Stores />
      <Categories.Container>
        {categories.map((value, index) => (
          <Categories.Category
            data-testid="category"
            to={`/section/${slug}/${id}/${value._id}`}
            key={index}
            image={value.image}
          >
            <h2>{value.name}</h2>
            <p>{value.description}</p>
          </Categories.Category>
        ))}
      </Categories.Container>
    </Wrapper>
  )
}

export default SubCategories
