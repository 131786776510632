export function productsUtils(products, shoppingCartProducts) {
  this.list = products
  this.shoppingCartProducts = shoppingCartProducts
  this.IVA = products[0].trade.iva / 100
  this.pointValue = products[0].trade.pointValue
}

productsUtils.prototype.getSubTotal = function() {
  const subTotal = this.list.reduce((acc, product) => {
    if (product.price_discount) {
      return acc + product.price_discount * this.shoppingCartProducts[product._id]
    }

    return acc + product.price * this.shoppingCartProducts[product._id]
  }, 0)

  return subTotal - this.getPointsValue()

}

productsUtils.prototype.getIVA = function() {
  return this.getSubTotal() * this.IVA
}

productsUtils.prototype.getTotal = function() {
  return this.getSubTotal() + this.getIVA()
}

productsUtils.prototype.getPointsValue = function(points) {
  return this.pointValue * this.points
}

productsUtils.prototype.setPoints = function(points) {
  this.points = points
}
