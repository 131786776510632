import React from 'react'
import styled from 'styled-components'
import { ShoppingCartContext } from '_globalState'
import { currencyFormatter, date } from '_utils'
import { Stars, Product as ProductComponent } from '_components'

const Product = ({ product }) => {
  const { shoppingCartProducts, removeProductToCart, setProductToCart } = React.useContext(ShoppingCartContext)
  const amount = shoppingCartProducts[product._id]

  function add() {
    setProductToCart(product._id)
  }

  function remove() {
    removeProductToCart(product._id)
  }

  if (!amount) return null

  return (
    <Product.Product>
      <Product.ImageContainer>
        <Product.Image src={product.image[0]} alt={product.name} />
      </Product.ImageContainer>
      <Product.ProductText>
        <Product.ProductTitle>{product.name}</Product.ProductTitle>
        <Stars amount={product.qualification} />
        <Product.PriceContainer>
          {product.price_discount ? <Product.Price>{currencyFormatter.format(product.price_discount)}</Product.Price> :  null}
          {product.price && <Product.Price type={product.price_discount ? 'discount' : 'default'}>{currencyFormatter.format(product.price)}</Product.Price>}
        </Product.PriceContainer>
        {product.expiration_date
          ? <Product.ExpirationDate>
            Hasta el {date.format(product.expiration_date)}
            </Product.ExpirationDate>
          : null
        }
      </Product.ProductText>
      <Product.ProductAmountContainer>
        <Product.ProductButton onClick={remove}>-</Product.ProductButton>
        <Product.ProductAmount>{amount}</Product.ProductAmount>
        <Product.ProductButton onClick={add}>+</Product.ProductButton>
      </Product.ProductAmountContainer>
    </Product.Product>
  )
}

Product.Product = styled.div`
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
  padding: 6px;
  border-radius: 6px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 100px;
  margin: 1rem 0;

  @media screen and (min-width: 768px){
    grid-template-columns: 100px 1fr 100px;
  }
`
Product.ImageContainer = styled(ProductComponent.Card.ImageContainer)`
  height: 100px;
  display: none;
  @media screen and (min-width: 768px){
    display: block;
  }
`
Product.Image = styled(ProductComponent.Card.Image)``
Product.Price = styled(ProductComponent.Card.Price)`
  margin-right: 5px;
`
Product.PriceContainer = styled(ProductComponent.Card.PriceContainer)`
  justify-content: flex-end;
  flex-direction: row-reverse;
`
Product.ProductTitle = styled(ProductComponent.Card.Title)``
Product.ExpirationDate = styled(ProductComponent.Details.ExpirationDate)`
  text-align: left;
`
Product.ProductText = styled.div`
  margin-left: 10px;
`
Product.ProductAmountContainer = styled.div`
  align-self: center;
  display: inline-flex;
  justify-content: center;
`

Product.ProductAmount = styled.p`
  margin: 0 10px;
  font-weight: bold;

`

Product.ProductButton = styled.button`
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 3px;
  text-align: center;
  padding: 0;
  line-height: 16px;
  width: 23px;
  height: 23px;
`

export default Product
