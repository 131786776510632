import React from 'react'
import { Input } from '_components'
import API from '_api'
import { SessionContext } from '_globalState'

const Profile = () => {
  const { logout } = React.useContext(SessionContext)
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [cellphone, setCellphone] = React.useState('')
  const [email, setEmail] = React.useState('')

  React.useEffect(() => {
    async function getProfile() {
      const response = await API.Account.detail()
      if (response.errors) {
        logout()
      }
      if (response._id) {
        setFirstName(response.first_name)
        setLastName(response.last_name)
        setCellphone(response.cellphone)
        setEmail(response.email)
      }
    }

    getProfile()
  }, [])

  return (
    <div>
      <form>
        <Input
          id="firstName"
          name="firstName"
          label="Nombres"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          type="text"
          required
        />
        <Input
          id="lastName"
          name="lastName"
          label="Apellidos"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          type="text"
          required
        />
        <Input
          id="phone"
          name="phone"
          label="Celular"
          value={cellphone}
          onChange={(e) => setCellphone(e.target.value)}
          type="text"
          required
        />
        <Input
          id="email"
          name="email"
          label="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          required
        />
      </form>
    </div>
  )
}

export { Profile }
