import React from 'react'
import styled from 'styled-components'

const Modal = ({ children, modalRef, showModal }) => {
  return (
    showModal ? (
      <Modal.Element>
        <Modal.Container ref={modalRef} >
          {children}
        </Modal.Container>
      </Modal.Element>
    ) : null
  )

}

Modal.Element = styled.div`
  position: fixed;
  font-family: Lato, sans-serif;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  `

Modal.Container = styled.div`
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 1.5rem;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
  border-radius: 6px;
  color: #91989f;
  opacity: 0;
  animation-name: entry;
  animation-duration: .3s;
  animation-fill-mode: forwards;

  i {
    font-size: 50px;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }

  h3 {
    color: #24282c;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
  }

  @keyframes entry {
    0% {
      opacity: 0;
      top: 20px;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      top: 55px;
      transform: scale(1);
    }
  }
  `

export default Modal
