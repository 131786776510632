import React from 'react'
import styled from 'styled-components'
import { currencyFormatter } from '_utils'
import { Button, Input } from '_components'
import Product from './Product'

const Summary = ({ products, branch, paymentType, setPaymentType, handlePayClick, branchs, currentPoints }) => {
  const [points, setPoints] = React.useState(0)
  products.setPoints(points)

  function add() {
    if (points < currentPoints) {
      products.setPoints(points + 1)
      setPoints(points + 1)
    }
  }

  function remove() {
    if (points > 0) {
      products.setPoints(points - 1)
      setPoints(points - 1)
    }
  }

  React.useEffect(() => {
    products.setPoints(points)
  }, [products])

  return (
    <Summary.Container>
      <Summary.Title as="p">Total - {branch}</Summary.Title>
      <Input
        as="select"
        value={paymentType}
        onChange={(e) => setPaymentType(e.target.value)}
      >
        <option value="buy_online">Pagar en internet</option>
        <option value="buy_site">Pagar en sitio</option>
      </Input>
      <Summary.Item>
        <span>Tus puntos: </span>
        <strong>{currentPoints}</strong>
      </Summary.Item>
      <Summary.Item>
        <span>Puntos a redimir: </span>
        <Product.ProductAmountContainer>
          <Product.ProductButton onClick={remove}>-</Product.ProductButton>
          <Product.ProductAmount>{points}</Product.ProductAmount>
          <Product.ProductButton onClick={add}>+</Product.ProductButton>
        </Product.ProductAmountContainer>
      </Summary.Item>
      <Summary.Item>
        <span>Descuento por puntos:</span>
        <strong>{currencyFormatter.format(products.getPointsValue(points))}</strong>
      </Summary.Item>
      <Summary.Item>
        <span>Subtotal:</span>
        <strong>{currencyFormatter.format(products.getSubTotal())}</strong>
      </Summary.Item>
      <Summary.Item>
        <span>IVA:</span>
      <strong>
        {currencyFormatter.format(products.getIVA())}
      </strong>
      </Summary.Item>
      <Summary.Item>
        <span>Total:</span>
        <strong>{currencyFormatter.format(products.getTotal())}</strong>
      </Summary.Item>
      <Summary.ButtonContainer>
        <Button onClick={() => handlePayClick(branchs[branch][0].idBranch, points)}>Pagar</Button>
      </Summary.ButtonContainer>
    </Summary.Container>
  )
}

Summary.Container = styled.div`
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;
  margin-bottom: 2rem;
`

Summary.Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #008ba7;
`

Summary.Item = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: .5rem 3px;
`

Summary.ButtonContainer = styled.div`
  width: 100%;
  text-align: right;
  padding-top: 1rem;
`

export default Summary
