import React from 'react'
import { session } from '_utils'
import API from '_api'

const SessionContext = React.createContext()

const SessionProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState(session.getCurrentUser())
  const [errorMessage, setErrorMessage] = React.useState('')

  async function login({ email, password }) {
    const response = await API.Auth.login(email, password)
    if (response.errors) {
      setErrorMessage(response.errors.token.message)
    } else {
      const user = {
        token: response.token,
        id: response.user,
        fullName: response.fullName
      }

      setCurrentUser(user)
      session.setCurrentUser(user)
    }
  }

  function logout() {
    setCurrentUser(null)
    session.removeCurrentUser()
  }

  React.useEffect(() => {
    API.Auth.verifyToken().then(({ data }) => {
      if(data.errors) {
        logout()
      }
    })
  }, [])

  return (
    <SessionContext.Provider value={{login, currentUser, errorMessage, logout}}>
      {children}
    </SessionContext.Provider>
  )
}

export {
  SessionProvider,
  SessionContext
}
