const axios = require('axios')
const { URL } = require('./constant')

class Section {

  async getSections () {
    const response = await axios.get(`${URL}/sections/list/sections`)
    return response.data
  }

}

export default new Section()