const axios = require('axios')
const { URL } = require('./constant')

class Stores {
  async getAll () {
    const response = await axios.get(`${URL}/trade/home/active`)
    return response.data
  }

}

export default new Stores()