import React from 'react'
import { Switch, BrowserRouter as Router } from 'react-router-dom'
import { Header, Product, PrivateRoutes, SessionRouter } from '_components'
import Cart from '_components/Cart'
import Section from '_components/section/Section'
import SectionCategory from '_components/section/Category'
import { ShoppingCartProvider, SearchProvider, SessionProvider } from '_globalState'
import Pages from '_pages'

import '_static/css/styles.min.css'

function App() {
  return (
    <Router>
      <div>
        <SessionProvider>
          <SearchProvider>
            <ShoppingCartProvider>
              <Header />
              <Switch>
                <SessionRouter exact path='/' component={Pages.Home} />
                <SessionRouter exact path='/producto/:slug/:id' component={Product.Details} />
                <SessionRouter exact path='/section/:slug/:id' component={Section} />
                <SessionRouter exact path='/section/:slug/:id/:category' component={SectionCategory} />
                <SessionRouter exact path='/carrito-de-compras' component={Cart} />
                <SessionRouter exact path='/busqueda' component={Pages.Search} />
                <SessionRouter exact path='/politica-de-privacidad' component={Pages.Privacy} />
                <SessionRouter
                  path='/cuenta'
                  component={(props) => <PrivateRoutes component={Pages.Account} {...props} />}
                />
              </Switch>
            </ShoppingCartProvider>
          </SearchProvider>
        </SessionProvider>
      </div>
    </Router>
  );
}

export default App;
