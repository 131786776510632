import React from 'react'

function useModal() {
  const [showModal, setShowModal] = React.useState(false)
  const modalRef = React.useRef(null)

  React.useEffect(() => {
    function handleKeyUp (event) {
      if (event.code === 'Escape') {
        setShowModal(false)
      }
    }

    function handleClickOutside(event) {
      if(modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false)
      }
    }
    
    window.addEventListener('keyup', handleKeyUp)
    window.addEventListener('mousedown', handleClickOutside )

    return () => {
      window.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setShowModal])

  return { showModal, setShowModal, modalRef }
}

export default useModal