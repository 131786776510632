import React from 'react'
import { Link } from 'react-router-dom'
import { ShoppingCartContext } from '_globalState'

import HomeLogo from '_static/img/home.png'
import PromotionLogo from '_static/img/promotion.png'
import DespensaLogo from '_static/img/despensa.png'
import DestacadoLogo from '_static/img/destacado.png'
import CarritoComprasLogo from '_static/img/carrito-compras.png'

const Menu = () => {
  const {cartAmount} = React.useContext(ShoppingCartContext)

  return (
    <div className='row no-gutters d-flex justify-content-xl-center' style={{ width: '90%', 'margin': 'auto', 'marginTop': '20px', 'maxWidth': '500px' }}>
      <div className='col-auto text-center m-auto'>
        <Link className='navbar-brand' to={{
            pathname: '/',
            state: {is_result: false}
        }}>
          <img src={HomeLogo} alt="casa" />
          <p style={{ 'fontFamily': 'Lato, sans-serif', 'fontSize': '12px', 'fontWeight': 'bold', color: '#008BA7' }}>Inicio</p>
        </Link>
      </div>
      <div className='col-auto text-center m-auto'>
        <img src={PromotionLogo} alt="escuento" />
        <p style={{ 'fontFamily': 'Lato, sans-serif', 'fontSize': '12px', 'fontWeight': 'normal', color: '#000000'}}>Promociones</p>
      </div>
      <div className='col-auto text-center m-auto'>
        <img src={DespensaLogo} alt="nevera" />
        <p style={{ 'fontFamily': 'Lato, sans-serif', 'fontSize': '12px', 'fontWeight': 'normal', color: '#000000' }}>Despensa</p>
      </div>
      <div className='col-auto text-center m-auto'>
        <img src={DestacadoLogo} alt="estrella" />
        <p style={{ 'fontFamily': 'Lato, sans-serif', 'fontSize': '12px', 'fontWeight': 'normal', color: '#000000' }}>Destacados</p>
      </div>
      <div className='col-auto text-center m-auto'>
        <Link className='navbar-brand' to={{
            pathname: '/carrito-de-compras',
            state: {is_result: false}
        }}>
          <img src={CarritoComprasLogo} alt="carro de compras" />
          {cartAmount > 0 && (
            <span className='shopping-cart-number'>{cartAmount}</span>
          )}
          <p style={{ 'fontFamily': 'Lato, sans-serif', 'fontSize': '12px', 'fontWeight': 'normal', color: '#000000' }}>Carrito de compras</p>
        </Link>
      </div>
    </div>
  )
}

export default Menu
