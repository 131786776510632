import React from 'react'
import API from '_api/index'
import styled from 'styled-components'
import { SessionContext } from '_globalState'

const Address = () => {
  const { logout } = React.useContext(SessionContext)
  const [addresses, serAddresses] = React.useState(null)

  React.useEffect(() => {
    async function getAddresses () {
      const response = await API.Address.all()
      if (response.errors) {
        logout()
      }
      serAddresses(response)
    }

    getAddresses()
  }, [])
  return (
    <div>
      <Address.Title>Mis direcciones</Address.Title>
      <Address.Container>
        {addresses && addresses.map((address, index) => (
          <Address.Item key={index}>
            <Address.IconItem>
              <i className="fas fa-home" />
            </Address.IconItem>
            <Address.TextItem>
              <p>{address.address} - {address.city}</p>
              <p>{address.neighborhood}</p>
              <p>Cel.: {address.otherCellphone}</p>
            </Address.TextItem>
          </Address.Item>
        ))}
      </Address.Container>
    </div>
  )
}

Address.Title = styled.p`
  color: #008BA7;
  font-size: 20px;
  font-weight: bold;
`

Address.Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  align-items: flex-start;
`

Address.Item = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: .5rem 1rem;
  font-weight: bold;
  border-radius: 6px;
`

Address.TextItem = styled.div`
  font-size: 14px;
  p {
    margin: 0;
    padding: 0;
  }
`

Address.IconItem = styled.span`
  font-size: 40px;
  margin-right: 1rem;
  opacity: 0.7;
  color: #008ba7;
`

export { Address }
