import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import Signin from '../SignIn'
import Signup from '../SignUp'
import useModal from '../Modal/useModal'


const SignInOrSignUp = () => {
  const [showSignIn, setShowSignIn] = React.useState(true)
  const {showModal, setShowModal, modalRef} = useModal()

  function handleClick(isSignIn) {
    if (!showModal) {
      setShowModal(true)
    }
    setShowSignIn(isSignIn)
  }


  return (
    <SignInOrSignUp.Element ref={modalRef}>
      <div>
        <Button onClick={() => handleClick(true)} type="secondary">Iniciar sesión</Button>
        <Button onClick={() => handleClick(false)} type="primary">Registrarme</Button>
      </div>
      {showModal && (
        <SignInOrSignUp.Container >
          {showSignIn ? <Signin /> : <Signup />}
        </SignInOrSignUp.Container>
      )}
    </SignInOrSignUp.Element>
  )
}

SignInOrSignUp.Element = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  font-family: Lato, sans-serif;
`

SignInOrSignUp.Container = styled.div`
  position: absolute;
  top: 20px;
  left: 5%;
  right: 5%;
  max-width: 300px;
  margin: 0 auto;
  z-index: 20000;
  background: #fff;
  padding: 1.5rem;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
  border-radius: 6px;
  color: #91989f;
  text-align: left;
  opacity: 0;
  animation-name: entry;
  animation-duration: .3s;
  animation-fill-mode: forwards;

  h3 {
    color: #24282c;
    font-size: 20px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
  }

  @keyframes entry {
    0% {
      opacity: 0;
      top: 20px;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      top: 55px;
      transform: scale(1);
    }
  }
`

export default SignInOrSignUp
