import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import API from '_api'
import { ShoppingCartContext, SessionContext } from '_globalState'
import { Wrapper, Button, Modal } from '_components'
import useModal from '_components/Modal/useModal'
import ProductCart from './Product'
import Summary from './Summary'
import { productsUtils } from './utils'

const Cart = () => {
  const { push } = useHistory()
  const [currentPoints, setCurrentPoints] = React.useState(0)
  const [showPaymentInofrmation, setShowPaymentInformation] = React.useState(false)
  const [paymentType, setPaymentType] = React.useState('buy_online')
  const { currentUser } = React.useContext(SessionContext)
  const { branchs, shoppingCartProducts } = React.useContext(ShoppingCartContext)
  const { setShowModal, showModal, modalRef } = useModal()

  function getProducts() {
    return Object.keys(shoppingCartProducts).map((key) => {
      return {_id: key, quantity: shoppingCartProducts[key]}
    })
  }

  async function handlePayClick(idBranch, points) {
    if(!currentUser) {
      setShowModal(true)
    } else {
      const data = {
        idBranch,
        products: getProducts(),
        pay_mode: paymentType,
        points_redeemed: points
      }

      const response = await API.Payment.create(data)
      if (typeof response.data === 'string') {
        window.location.href = response.data
      } else {
        setShowPaymentInformation(true)
        setShowModal(true)
      }
    }
  }

  function handleSuccesPaymentCreated() {
    setShowPaymentInformation(false)
    setShowModal(true)
    push('/cuenta')
  }

  React.useEffect(() => {
    async function getProfile() {
      const response = await API.Account.detail()
      if (response._id) {
        setCurrentPoints(response.points)
      }
    }

    getProfile()
  }, [])

  return (
    <Wrapper>
      <Cart.Title>Carrito de compras</Cart.Title>
      <Cart.BrnachContainer>
        {Object.keys(branchs).map((branch, index) => {
          const products = new productsUtils(branchs[branch], shoppingCartProducts)

          return(
            <React.Fragment key={index}>
              {products.list ? <Cart.BranchTitle>Almacén - {branch}</Cart.BranchTitle> : null}
              <Cart.Container>
                <Cart.ProductContainer>
                  {products.list.map((product, index) => (
                    <ProductCart product={product} key={index} />
                  ))}
                </Cart.ProductContainer>
                <Summary
                  handlePayClick={handlePayClick}
                  setPaymentType={setPaymentType}
                  paymentType={paymentType}
                  products={products}
                  branch={branch}
                  branchs={branchs}
                  currentPoints={currentPoints}
                />
              </Cart.Container>
            </React.Fragment>
          )
        })}
        <Modal showModal={showModal} modalRef={modalRef}>
          {!showPaymentInofrmation ? (
            <div>
              <i className="fas fa-exclamation-circle" />
              <h3>Acción requerida</h3>
              <Cart.Message>
                Para continuar con la compra es necesario <strong>Crear un cuenta</strong> o <strong>Iniciar sesión.</strong>
              </Cart.Message>
              <Cart.ButtonContainer>
                <Button onClick={() => setShowModal(false)}>Aceptar</Button>
              </Cart.ButtonContainer>
            </div>
          ) : (
            <div>
              <i className="fas fa-check-circle" style={{ color: 'green' }}/>
              <h3>Transacción creada</h3>
              <Cart.Message>
                Hemos Guardo esta promoción en la Billetera. Presenta el QR en el Almacén.
              </Cart.Message>
              <Cart.ButtonContainer>
                <Button onClick={handleSuccesPaymentCreated}>Ir a mi billetera</Button>
              </Cart.ButtonContainer>
            </div>
          )}
        </Modal>
      </Cart.BrnachContainer>
    </Wrapper>
  )
}

Cart.Title = styled.p`
  font-weight: bold;
  color: #022E37;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding-bottom: 5px;
`

Cart.BranchTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: #008ba7;
`

Cart.Container = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 3rem;
  }
`

Cart.BrnachContainer = styled.div``
Cart.ProductContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  width: 100%;
`

Cart.Message = styled.p`
  font-size: 17px;
  text-align: center;
`

Cart.ButtonContainer = styled.div`
  text-align: center;
`

export default Cart
