import React from 'react'
import styled from 'styled-components'
import API from '_api'
import { Wrapper, Button } from '_components'
import { currencyFormatter, date } from '_utils'
import { ShoppingCartContext } from '_globalState'
import Stars from '../Stars'

const DetailProduct = ({ match }) => {
  const { id } = match.params
  const { setProductToCart } = React.useContext(ShoppingCartContext)
  const [product, setProduct] = React.useState({})

  React.useEffect(() => {
    async function getProduct (id) {
      const product = await API.Product.detailProduct(id)
      setProduct(product)
    }
    getProduct(id)

  }, [id])

  if (!product._id) {
    return <div>Loading...</div>
  }

  return (
    <Wrapper>
      <DetailProduct.Conatiner>
      <DetailProduct.Gallery>
        {product.image.map((image, index) => (
          <DetailProduct.GalleryItem key={`gallery-item-${index}`} src={image} />
        ))}
      </DetailProduct.Gallery>
      <div>
        <DetailProduct.Header>
          <div>
            <DetailProduct.Name>{product.name}</DetailProduct.Name>
            <DetailProduct.Trade>{product.nameBranch}</DetailProduct.Trade>
            <Stars amount={product.qualification} />
          </div>
          <div>
            {product.point ? <DetailProduct.Points>({product.point}pts)</DetailProduct.Points> : null}
            <DetailProduct.PriceContainer>
              {product.price ? <DetailProduct.Discount>{currencyFormatter.format(product.price)}</DetailProduct.Discount> : null}
              {product.price_discount ? <DetailProduct.Price>{currencyFormatter.format(product.price_discount)}</DetailProduct.Price> : null}
            </DetailProduct.PriceContainer>
            {product.expiration_date ? <DetailProduct.ExpirationDate>Hasta el {date.format(product.expiration_date)}</DetailProduct.ExpirationDate> : null}
          </div>
        </DetailProduct.Header>
        <DetailProduct.ButtonContainer>
          <Button onClick={() => setProductToCart(product._id)}>Añadir al carrito</Button>
        </DetailProduct.ButtonContainer>
        <DetailProduct.Description>{product.description}</DetailProduct.Description>
      </div>
    </DetailProduct.Conatiner>
  </Wrapper>
  )
}

DetailProduct.Conatiner = styled.div`
  width: 100%;
  margin-top: 1rem;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 4rem;
  }
`

DetailProduct.Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
`
DetailProduct.GalleryItem = styled.img`
  width: 100%;
  max-width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: zoom-in;
`

DetailProduct.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
DetailProduct.Name = styled.h2`
  color: #022E37;
  font-weight: bold;
  font-size: 20px;
  text-transform: lowercase;
  margin-bottom: 4px;
  &::first-letter {
    text-transform: uppercase;
  }
`
DetailProduct.Trade = styled.span`
  color: #868E96;
  font-size: 14px;
  margin-bottom: 0;
`

DetailProduct.Points = styled.p`
  font-size: 14px;
  color: #008BA7;
  font-weight: bold;
  text-align: right;
  margin-bottom: 0;
`

DetailProduct.PriceContainer = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

DetailProduct.Discount = styled.p`
  color: #868E96;
  font-size: 16px;
  text-decoration: line-through;
  margin-bottom: 0;
`

DetailProduct.Price = styled.p`
  font-size: 18px;
  color: #008BA7;
  font-weight: bold;
  padding-left: 7px;
  margin-bottom: 0;
`

DetailProduct.ExpirationDate = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #022E37;
  text-align: right;
`

DetailProduct.ButtonContainer = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: right;
`

DetailProduct.Description = styled.p`
  color: #022E37;
  font-size: 15px;
  line-height: 1.7;
`
export default DetailProduct
