import Home from './Home'
import Account from './Account'
import Search from './Search'
import Privacy from './Privacy'

export default {
  Home,
  Account,
  Search,
  Privacy
}
