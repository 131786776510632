import Product from './product'
import Stores from './stores'
import Section from './section'
import Category from './category'
import Auth from './auth'
import Account from './account'
import Address from './address'
import Shopping from './shopping'
import Referral from './referrals'
import Payment from './payment'

const API = {
  Auth,
  Product,
  Stores,
  Section,
  Category,
  Account,
  Address,
  Shopping,
  Referral,
  Payment
}

export default API
