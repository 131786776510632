import React from 'react'
import { Route } from 'react-router-dom'
import { SessionContext } from '_globalState'
import API from '_api'

const SessionRouter = ({ component: Component, ...rest }) => {
  const { logout } = React.useContext(SessionContext)

  React.useEffect(() => {
    API.Auth.verifyToken().then(({ data }) => {
      if(data.errors) {
        logout()
      }
    })
  }, [Component])

  return (
    <Route
      {...rest}
      render={(props) => <Component {...props}/>}
    />
  )
}

export default SessionRouter
