import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { slugify } from '_utils'
import API from '_api'

const Categories = () => {
  const [sections, setSection] = React.useState([])

  React.useEffect(() => {
    async function getSections () {
      const response = await API.Section.getSections()
      setSection(response)
    }

    getSections()
  }, [])

  return (
    <Categories.Container>
      {sections.length ? sections.map((value, index) => (
        <Categories.Category
          data-testid="category"
          to={`/section/${slugify(value.name)}/${value._id}`}
          key={index}
          image={value.image}
        >
          <h2>{value.name}</h2>
          <p>{value.description}</p>
        </Categories.Category>
      )) : null}
    </Categories.Container>
  )
}

Categories.Container = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 1.5rem;
  margin-bottom: 4rem;
`

Categories.Category = styled(Link)`
  width: 100%;
  border-radius: 4px;
  padding: 1rem 1.5rem;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background-image: ${({ image }) => `url(${image})`};
  transition: all .3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  p {
    margin: 0;
    font-size: 13px;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &:hover {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    transform: scale(1.02);
  }
`

export default Categories
