import axios from 'axios'
import { session } from '_utils'
import { URL } from './constant'

class Auth {
  async login (email, password) {
    const response = await axios.post(`${URL}/auth`, {
      email: email,
      password: password
    })

    return response.data
  }


  async verifyToken () {
    const token = session.getToken()
    const verify = await axios.post(`${URL}/auth/verify`, { token }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    return verify
  }
}

export default new Auth()
