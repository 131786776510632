import React from 'react'
import styled from 'styled-components'

const Input = ({ label, hasError, ...props }) => {
  return (
    <Input.Container>
      <Input.Label htmlFor={props.id}>{label}</Input.Label>
      <Input.Element {...props} hasError={hasError} />
      {hasError ? <Input.ErrorMessage>{hasError}</Input.ErrorMessage> : null}
    </Input.Container>
  )
}

Input.Element = styled.input`
  height: 40px;
  background-color: #F7F7F7;
  border: 1px solid ${({ hasError }) => hasError ? 'red' : 'rgba(0,0,0,0.08)'};
  border-radius: 2px;
  padding: 0 10px;
`

Input.Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`

Input.Label = styled.label`
  font-size: 14px;
  color: #868E96;
  font-weight: bold;
`

Input.ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
  text-align: left;
`

export default Input
