import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '_static/img/logo.png'

const Brand = () => {
  return (
    <Link className='navbar-brand' to={{
      pathname: '/',
      state: {is_result: false}
    }}>
      <img src={Logo} alt="Logo de Ganga Hoy" />
    </Link>
  )
}

export default Brand
