import React from 'react'
import { useGetActiveProducts } from '_hooks'
import { Product, Categories, Stores, Wrapper } from '_components'

const Home = () => {
  const {products, loading, getActiveProducts} = useGetActiveProducts()
  const [element, setElement] = React.useState(null)
  const observer = React.useRef(new IntersectionObserver((entries) => {
    const first = entries[0]
    if (first.isIntersecting) {
      getActiveProducts()
    }
  }, { threshold: 1 }))

  React.useEffect(() => {
    getActiveProducts()
  }, [])


  React.useEffect(() => {
    const currentElement = element
    const currentObserver = observer.current

    if (currentElement) {
      currentObserver.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement)
      }
    }
  }, [element])

  return (
    <Wrapper>
      <Stores />
      <Categories />
      <div>
        <h3 style={{ 'color': '#868E96','fontSize': '16px'}}>
          Productos
        </h3>
        <Product.List products={products} />
        {!loading && <div ref={setElement} id="intersection-observer"></div>}
      </div>
    </Wrapper>
  )
}

export default Home
