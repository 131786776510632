import React from 'react'
import API from '_api/index'
import { date } from '_utils'
import styled from 'styled-components'
import { SessionContext } from '_globalState'

const Shopping = () => {
  const { logout } = React.useContext(SessionContext)
  const [shopping, setShopping] = React.useState([])

  React.useEffect(() => {
    async function getShoppings () {
      const response = await API.Shopping.all()
      if (response.errors) {
        logout()
      }
      setShopping(response)
    }

    getShoppings()
  }, [])

  return (
    <div>
      <Shopping.Title>Mis compras</Shopping.Title>
      <Shopping.Container>
        {shopping.map((shopping, index) => (
          <Shopping.Item key={index}>
            <Shopping.IconItem>
              <i className="fas fa-shopping-cart" />
            </Shopping.IconItem>
            <Shopping.TextItem>
              <p>{shopping.nameBranch}</p>
              <p>${shopping.totalPriceBill}</p>
              <p>{date.format(shopping.start_date)}</p>
            </Shopping.TextItem>
          </Shopping.Item>
        ))}
      </Shopping.Container>
    </div>
  )
}

Shopping.Title = styled.p`
  color: #008BA7;
  font-size: 20px;
  font-weight: bold;
`

Shopping.Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  align-items: flex-start;
`

Shopping.Item = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: .5rem 1rem;
  font-weight: bold;
  border-radius: 6px;
`

Shopping.TextItem = styled.div`
  font-size: 14px;
  p {
    margin: 0;
    padding: 0;
  }
`

Shopping.IconItem = styled.span`
  font-size: 40px;
  margin-right: 1rem;
  opacity: 0.7;
  color: #008ba7;
`

export { Shopping }
