import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'

const Stars = ({ amount }) => {
  const stars = new Array(5).fill('star')
  function getStarColor(index) {
    return index < amount ? 'rgb(246,161,26)' : '#CECECE';
  }

  return (
    <div>
      {stars.map((_, index) => (
        <Stars.Element fill={getStarColor(index)} key={Math.random(1)} data-testid="star" />
      ))}
    </div>
  )
}

Stars.Element = styled(Icon.Star)`
  transform: scale(1.1);
  margin: 0 2px;
`

export default Stars
