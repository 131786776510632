import axios from 'axios'
import { session } from '_utils'
import { URL } from './constant'

class Address {
  async all () {
    const response = await axios.get(`${URL}/address/list`, {
      headers: {
        'Authorization': `Bearer ${session.getToken()}`
      }
    })

    return response.data
  }
}

export default new Address()
