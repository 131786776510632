export const session = {
  getCurrentUser () {
    const currentUser = JSON.parse(window.localStorage.getItem('currentUser'))
    return currentUser
  },
  getToken() {
    const currentUser = this.getCurrentUser()
    return currentUser ? currentUser.token : null
  },
  setCurrentUser(currentUser) {
    window.localStorage.setItem('currentUser', JSON.stringify(currentUser))
    return currentUser
  },
  removeCurrentUser() {
    window.localStorage.removeItem('currentUser')
  },
  getCartProducts() {
    const cartProducts = JSON.parse(window.localStorage.getItem('cartProducts'))
    return cartProducts
  },
  setCartProducts(products) {
    window.localStorage.setItem('cartProducts', JSON.stringify(products))
    return products
  }
}
