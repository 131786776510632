import  axios from 'axios'
import { session } from '_utils'
import { URL } from './constant'

class Account {
  async detail () {
    const response = await axios.post(`${URL}/account/detail`, {}, {
      headers: {
        authorization: `Bearer ${session.getToken()}`
      }
    })

    return response.data
  }
}

export default new Account()
