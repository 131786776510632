import React from 'react'
import styled from 'styled-components'
import API from '_api'

const Stores = () => {
  const [stores, setStores] = React.useState([])

  React.useEffect(() => {
    async function getStores() {
      const stores = await API.Stores.getAll()
      setStores(stores)
    }

    getStores()
  }, [])

  return (
    <Stores.Container>
      <Stores.Title>Tiendas</Stores.Title>
      <Stores.Brands>
        {stores.length ? stores.map((trade) => (
          <div key={trade._id}>
            <img src={trade.image} alt="publicidad" />
          </div>
        )) : null}
      </Stores.Brands>
    </Stores.Container>
  )
}

Stores.Container = styled.div`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 4rem;
`

Stores.Title = styled.h3`
  color: #868E96;
  font-size: 16px;
`

Stores.Brands = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 90px);
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;

  > div {
    text-align: center;
    height: 60px;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    max-width: 100%;
    max-height: 50px;
  }

  @media screen and (min-width: 768px) {
    justify-content: left;
  }
`

export default Stores
