import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SessionContext } from '_globalState'
import Brand from './Brand'
import Search from '../Search'
import Menu from './Menu'
import SignInOrSignUp from './SignInOrSignUp'

const Header = () => {
  const { currentUser } = React.useContext(SessionContext)

  return (
    <div>
      <div className='align-items-center'>
        <nav className='navbar navbar-dark navbar-expand-lg navigation-clean'>
          <div className='container'>
            <Brand />
            <React.Fragment>
              <button data-toggle="collapse" className='navbar-toggler' data-target="#navcol-1">
                <span className='sr-only'>Toggle navigation</span>
                <span className='navbar-toggler-icon' />
              </button>
              <div className='collapse navbar-collapse' id="navcol-1">
                <Search />
                {!currentUser
                  ? <SignInOrSignUp />
                  : (
                    <Header.Account>
                      <Header.AccountWelcome>Bienvenido, {currentUser.fullName}</Header.AccountWelcome>
                      <Header.AccountLink to="/cuenta">Ir a mi cuenta</Header.AccountLink>
                    </Header.Account>
                  )
                }
              </div>
            </React.Fragment>
          </div>
        </nav>
      </div>
      <hr style={{ width: '100%', height: '6px', 'backgroundColor': '#EFFF0D', margin: '0px 0px' }} />
      <Menu />
    </div>
  )
}

Header.Account = styled.div``
Header.AccountWelcome = styled.p`
  font-size: 13px;
  margin-bottom: -4px;
`
Header.AccountLink = styled(Link)`
  font-weight: bold;
  color: #fff;

  &:hover {
    color: #fff;
  }
`


export default Header
