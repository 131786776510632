import  axios from 'axios'
import { session } from '_utils'
import { URL } from './constant'

const payment = {
  async create(data) {
    const response = await axios.post(`${URL}/bill`, data, {
      headers: {
        'Authorization': `Bearer ${session.getToken()}`
      }
    })
    return response
  }
}

export default payment
