import React from 'react'
import styled from 'styled-components'
import { SessionContext } from '_globalState'
import Input from '../Input'
import Button from '../Button'

const Signin = () => {
  const { login, errorMessage } = React.useContext(SessionContext)
  async function handleSubmit(event) {
    event.preventDefault()
    const form = event.target

    login({
      email: form.email.value,
      password: form.password.value
    })

  }

  return (
    <Signin.Container>
      <div>
        <h3>¡Bienvenido!</h3>
        <p>Inicia sesión y empieza a disfrutar de los mejores <strong>descuentos</strong></p>
      </div>
      <form id="signin" onSubmit={handleSubmit}>
        <Input
          id="email"
          name="emial"
          label="Correo"
          type="email"
          placheolder="ejemplo@gmail.com"
          required
        />
        <Input
          id="password"
          name="password"
          label="Contraseña"
          type="password"
          required
          hasError={errorMessage}
        />
        <a>¿Olvidaste tu contraseña?</a>
        <Signin.ButtonContiner>
          <Button type="submit">Iniciar sesión</Button>
        </Signin.ButtonContiner>
      </form>
    </Signin.Container>
  )
}

Signin.Container = styled.div`
  a {
    color: #868E96;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
  }
`

Signin.ButtonContiner = styled.div`
  text-align: right;
  margin-top: 1rem;
`

  export default Signin
