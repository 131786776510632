const axios = require('axios')
const { URL } = require('./constant')

const category = {
  async filterBySection(section, page) {
    const response = await axios.get(`${URL}/category/sections/list/${section}/${page}`)
    return response.data
  },
  async detail (id) {
    const response = await axios.get(`${URL}/category/detail/${id}`)
    return response.data
  }
}

export default category
