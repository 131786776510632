import currencyFormatter from 'currency-formatter'

function format (value) {
  return currencyFormatter.format(Number(value), { code: 'COP', precision: 0, thousand: '.' }).replace(/\s/g, '')
}

function unformat(value) {
  return currencyFormatter.unformat(value, { code: 'COP' })
}

export { format, unformat }
