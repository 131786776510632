import styled from 'styled-components'

const Button = styled.button`
  color: ${({ type }) => type === 'primary' ? '#008ba7' : '#fff'};
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
  background-color: ${({ type }) => type === 'primary' ? '#fff' : '#008ba7'};
  margin: 0 7px;
  outline: none;
`

export default Button
