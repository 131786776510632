import React from 'react'
import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
import { SessionContext } from '_globalState'

const SideBar = () => {
  const {logout} = React.useContext(SessionContext)
  return (
    <SideBar.List>
      <SideBar.ListItem>
        <Link activeClassName="link-active" exact to="/cuenta">
          <i className="fas fa-user-circle" />Mi cuenta
        </Link>
      </SideBar.ListItem>
      <SideBar.ListItem>
        <Link activeClassName="link-active" to="/cuenta/direcciones">
          <i className="fas fa-map-marker-alt" />Mis direcciones
        </Link>
      </SideBar.ListItem>
      <SideBar.ListItem>
        <Link activeClassName="link-active" to="/cuenta/fidelizacion">
          <i className="fas fa-star" />Programas de fidelización
        </Link>
      </SideBar.ListItem>
      <SideBar.ListItem>
        <Link activeClassName="link-active" to="/cuenta/descuentos">
          <i className="fas fa-tag" />Mis descuentos
        </Link>
      </SideBar.ListItem>
      <SideBar.ListItem>
        <Link activeClassName="link-active" to="/cuenta/compras">
          <i className="fas fa-shopping-cart" />Mis compras
        </Link>
      </SideBar.ListItem>
      <SideBar.ListItem>
        <Link activeClassName="link-active" to="/cuenta/afiliados">
          <i className="fas fa-user-friends" />Mis referidos
        </Link>
      </SideBar.ListItem>
      <SideBar.ListItem>
        <Link to="" onClick={logout}>
          <i className="fas fa-door-open" />Cerrar sesión
        </Link>
      </SideBar.ListItem>
    </SideBar.List>
  )
}

SideBar.List = styled.ul`
  width: 100%;
  list-style: none;
`

SideBar.ListItem = styled.li`
  width: 100%;
  color: #022E37;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  .link-active {
    font-weight: bold;

    i {
      opacity: 1;
      margin-right: 15px;
    }
  }
  a {
    color: inherit;
    display: block;
    padding: 1rem 0;

    &:hover {
      font-weight: bold;
      text-decoration: none;
      i {
        margin-right: 15px;
      }
    }
  }
  i {
    display: inline-block;
    margin-right: 10px;
    opacity: 0.6;
    transition: .3s all;
  }
`
export { SideBar }
