import React from 'react'
import { SearchContext } from '_globalState'
import { Wrapper, Product, Stores } from '_components'

const Search = () => {
  const {result} = React.useContext(SearchContext)
  return (
    <Wrapper>
      <Stores />
      {(result && result.length)
        ? <Product.List products={result} />
        :  <h3>Producto no encontrado</h3>
      }
    </Wrapper>
  )
}

export default Search
