import  axios from 'axios'
import { session } from '_utils'
import { URL } from './constant'

class Shopping {
  async all () {
    const response = await axios.get(`${URL}/bill/list/verify/user/false`, {
      headers: {
        'Authorization': `Bearer ${session.getToken()}`
      }
    })
    return response.data
  }
}

export default new Shopping()
