import React from 'react'
import API from '_api/index'
import styled from 'styled-components'
import { SessionContext } from '_globalState'

const Referrals = () => {
  const { logout } = React.useContext(SessionContext)
  const code = 'HJF1234k'
  const [isCopied, setIsCopied] = React.useState(false)
  const [referrals, setReferrals] = React.useState([])

  function copyTextToClipboard(code) {
    navigator.clipboard.writeText(code)
    setIsCopied(true)
  }

  React.useEffect(() => {
    async function getReferrals () {
      const response = await API.Referral.filterByPage(1)
      if (response.errors) {
        logout()
      }
      setReferrals(response)
    }

    getReferrals()
  }, [])

  React.useEffect(() => {
    if(isCopied) {
      setTimeout(() => {
        setIsCopied(false)
      }, 1000)
    }
  }, [isCopied])

  return (
    <div>
      <Referrals.Title>Mis referidos</Referrals.Title>
      <Referrals.CodeContainer>
        {isCopied ? <Referrals.IsCopied>Copiado!</Referrals.IsCopied> : null}
        <Referrals.CodeTitle>Código de referido</Referrals.CodeTitle>
        <Referrals.Code onClick={() => copyTextToClipboard(code)}>{code}</Referrals.Code>
      </Referrals.CodeContainer>
      <Referrals.Container>
        {referrals.map((referral, index) => (
          <Referrals.Item key={index}>
            <Referrals.IconItem>
              <i className="fas fa-user" />
            </Referrals.IconItem>
            <Referrals.TextItem>
              <p>Pepito Perez</p>
              <p>Cel.: 3125201343</p>
            </Referrals.TextItem>
          </Referrals.Item>
        ))}
      </Referrals.Container>
    </div>
  )
}

Referrals.Title = styled.p`
  color: #008BA7;
  font-size: 20px;
  font-weight: bold;
`

Referrals.Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  align-items: flex-start;
`

Referrals.Item = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: .5rem 1rem;
  font-weight: bold;
  border-radius: 6px;
`

Referrals.TextItem = styled.div`
  font-size: 14px;
  p {
    margin: 0;
    padding: 0;
  }
`

Referrals.IconItem = styled.span`
  font-size: 40px;
  margin-right: 1rem;
  opacity: 0.7;
  color: #008ba7;
`
Referrals.CodeContainer = styled.div`
  padding-bottom: 1rem;
  position: relative;
  margin-bottom: 1rem;
`

Referrals.CodeTitle = styled.p`
  font-weight: bold;
  margin-bottom: 3px;
`

Referrals.Code = styled.div`
  display: inline-block;
  color: #fff;
  background-color: #008ba7;
  padding: 5px 1rem;
  text-transform: uppercase;
  border-radius: 3px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`

Referrals.IsCopied = styled.p`
  font-size: 13px;
  margin: 0;
  bottom: 20px;
  left: 120px;
  position: absolute;
  color: #fff;
  background: rgba(0,0,0,0.9);
  padding: 3px 7px;
  border-radius: 3px;
`

export { Referrals }
