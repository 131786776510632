import React from 'react'
import styled from 'styled-components'

const Discounts = () => {
  return (
    <div>
      <Discounts.Title>Mis descuentos</Discounts.Title>
    </div>
  )
}

Discounts.Title = styled.p`
  color: #008BA7;
  font-size: 20px;
  font-weight: bold;
`


export { Discounts }
