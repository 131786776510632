import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { SessionContext } from '_globalState'

const PrivateRoutes = ({ component: Component, ...rest }) => {
  const { currentUser } = React.useContext(SessionContext)

  return (
    <Route
      {...rest}
      render={props => {
        if (!currentUser) return <Redirect to="/" />

        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoutes
