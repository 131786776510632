import React from 'react'
import Product from '_components/Product'
import API from '_api'

const Category = (props) => {
  const category = props.match.params.category
  const [products] = React.useState([])
  const [nameCategory, setNameCategory] = React.useState('')

  React.useEffect(() => {
    async function getDetail () {
      const response = await API.Category.detail(category)
      if (response.name) {
        setNameCategory(response.name)
      }
    }

    getDetail()
  }, [])

  return (
    <div>
      <div className='container'>
        <h3 className='text-left title-promotions' style={{ 'color': '#868E96','fontSize': '20px','fontFamily': 'Lato, sans-serif', 'fontWeight': 'normal', 'fontStyle': 'normal', 'marginTop': '2%', 'marginBottom': '2%', paddingLeft: '15px' }}>
          Categoría - {nameCategory}
        </h3>
        <Product.List products={products} />
      </div>
    </div>
  )
}

export default Category
