import React from 'react'
import { SearchContext } from '_globalState'
import MapLogo from '_static/img/map.png'

const Search = () => {
  const [searchValue, setSearchValue] = React.useState('')
  const {search} = React.useContext(SearchContext)

  function handleSubmit(e) {
    e.preventDefault()
    search(searchValue)
    setSearchValue('')
  }

  return (
    <ul
      className='nav navbar-nav d-xl-flex flex-nowrap justify-content-xl-center align-items-xl-center'
      style={{margin: '0 auto', height: 'auto'}}
    >
      <li className='nav-item' role="presentation" style={{ margin: '0 auto' }}>
        <form onSubmit={handleSubmit}>
          <input
            value={searchValue}
            onChange={({ target }) => setSearchValue(target.value)}
            className='form-control-lg float-left d-xl-flex search-field'
            type='search'
            id='search-field'
            name='search'
            placeholder='¿Qué buscas?'
            style={{
              'color': '#ffffff',
              'height': '39px',
              'fontFamily': 'Lato, sans-serif',
              'fontSize': '14px',
              'backgroundColor': '#7fc5d3',
              'minWidth': '300px',
              'marginTop': '1rem',
              'marginBottom': '1rem'
            }}
          />
        </form>
      </li>
      <li className='nav-item text-center d-none d-lg-block flex-nowrap' role="presentation">
        <a className='nav-link text-center d-inline-block d-lg-flex align-items-lg-center flex-lg-nowrap' href="#" style={{ 'fontFamily': 'Lato, sans-serif', 'fontWeight': 'bold', color: '#ffffff', 'fontSize': '12px' }}>
          <img src={MapLogo} alt="" />&nbsp;<strong>Cerca</strong>
        </a>
      </li>
    </ul>
  )
}



export default Search
