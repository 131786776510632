import  axios from 'axios'
import { session } from '_utils'
import { URL } from './constant'

class Referral {
  async filterByPage (page) {
    const response = await axios.get(`${URL}/referral/list/${page}`, {
      headers: {
        'Authorization': `Bearer ${session.getToken()}`
      }
    })

    return response.data
  }
}

export default new Referral()
